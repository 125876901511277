import React, { useState, useEffect, useLayoutEffect } from 'react';
import { connect } from 'react-redux';
import * as Actions from '../../actions/actions';

import {useLocation, useParams} from "react-router";
import {getQuicksightData} from "../../services/API";
import CircularProgress from "@mui/material/CircularProgress";

var HEURISTIC = "HEURISTIC"
var STITCHING_VIEW = "STITCHING_VIEW"
var VOR_LOG = "VOR_LOG"

const QuickSightData = () => {
    const location = useLocation();
    const [progress,setProgress] = useState(false);
    const [data,setData] = useState("");
    const [fileType,setFileType] = useState("")

    useEffect(() => {
        try{
            document.getElementById("sideMenu").style.display = "none";
            getData();
        }catch (ex){
            console.log(ex.message);
        }
    }, []);

    let getData = async ()=>{
        const searchParams = new URLSearchParams(location.search);
        let params = {};

        for (let param of searchParams) {
            params[param[0]] = param[1];
        }
        console.log(params)
        try {
            setProgress(true);
            setFileType(params.data_type);
            let logData = await getQuicksightData(params.s3_url,params.data_type,params.customer_id,params.dc_id);
            console.log(logData);
            if(logData && logData.data){
                if(params.data_type == HEURISTIC){
                    setData(JSON.stringify(logData.data,null,4))
                }else{
                    setData(logData.data)
                }

            }
            setProgress(false);
        }catch (ex){
            console.log(ex.message)
            setProgress(false);
        }
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                <div style={{position:"absolute",top:"50%",left:"50%"}}>
                        {progress?<CircularProgress />:""}
                </div>

                {fileType == VOR_LOG?<textarea style={{width:"100%",border:"1px solid #dfd9d9",height:(window.innerHeight-150)}} value={data} readOnly />:""}
                {fileType == STITCHING_VIEW? <iframe srcDoc={data} style={{width:"100%",border:"1px solid #dfd9d9",height:(window.innerHeight-150)}} />:""}
                {fileType == HEURISTIC?<textarea style={{width:"100%",height:(window.innerHeight-150)}} value={data} readOnly />:""}

            </div>


        </>
    )
}



export default (QuickSightData);
